<template>
  <div feature-slide v-editable="blok">
    <div class="feature-content">
      <div class="feature-label" v-if="!blok?.hide_label_on_slide" v-text="blok.label"></div>
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''" v-html="description"></div>
      <div v-if="blok.buttons" class="feature-buttons">
        <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
      </div>
    </div>
    <div class="feature-image">
      <img v-if="blok.image" :src="optimizeImage(blok.image.filename)" :alt="`Feature Image ${blok.image.alt}`" />
    </div>
  </div>
</template>
<style src="~/assets/scss/section/feature-slide.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));
</script>
